import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getToken } from '../../services/login';
import { USER_PROFILE, INITIAL_PATH } from '../../models/common/sys-msg';

export default function LoginRedirect() {
    const navigate = useNavigate();
    const [search, ] = useSearchParams();
    let code = search.get('code');
        if(code){
            getToken(code).then(result => {
                result && sessionStorage.setItem(USER_PROFILE, JSON.stringify(result));
                if(result && result.code > 0){
                    let initialPath = sessionStorage.getItem(INITIAL_PATH);
                    if(initialPath){
                        navigate(initialPath);
                        sessionStorage.removeItem(INITIAL_PATH);
                    }else{
                        navigate('/home', {state: {isNewUser: false}});
                    }
                }else if(!result || result?.code === 0){
                    navigate('/home', {state: {isNewUser: true}});
                }
            })
        }
    return (
        <div className='center'>登陆中...</div>
    )
}
