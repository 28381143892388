import { Navigate } from 'react-router-dom';
import { INITIAL_PATH, INITIAL_PATH_SEARCH } from '../models/common/sys-msg';
import { isMobileWxBrowser, isCourseUrlAndFindCourseId } from '../utilities/browserHelper';
import { getCourseSchema } from '../services/home';

export const LoginWrapper = (props: {currentUser: any, children: any}) => {
    if(isMobileWxBrowser()){
        let courseId = isCourseUrlAndFindCourseId();
        if(courseId){
            getCourseSchema(courseId).then(result => {
                window.location.href = result?.openlink ?? "weixin://dl/business/?t=cF78wgoFjpe";
            });
        }else{
            window.location.href = "weixin://dl/business/?t=cF78wgoFjpe"
        }
    }
    const {currentUser, children} = {...props};
    if(!currentUser.token){
        sessionStorage.setItem(INITIAL_PATH, window.location.pathname);
        window.location.search && sessionStorage.setItem(INITIAL_PATH_SEARCH, window.location.search);
    }
    return currentUser.token ? children : <Navigate to="/login" replace />
};