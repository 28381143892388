import '../../styles/login.scss';
import { isMobileWxBrowser } from '../../utilities/browserHelper';

export default function Login() {
    const appId = process.env.REACT_APP_WX_APP_ID;
    const redirectUri = process.env.REACT_APP_PORTAL_LOGIN_REDIRECT;
    const wxBrowser = isMobileWxBrowser();

    return (
        !wxBrowser ? <div className='login' style={{"minHeight": window.innerHeight}}><iframe title='login' id='ifmCodeArea' className='loginCode' frameBorder='0' sandbox='allow-scripts allow-same-origin allow-top-navigation' scrolling='no'  
        src={`https://open.weixin.qq.com/connect/qrconnect?href=${window.location.origin}/assets/wxlogin.css&appid=${appId}&redirect_uri=${redirectUri}loginRedirect&response_type=code&scope=snsapi_login&state=state#wechat_redirect`} height={500} width={660}>
        </iframe></div> : <></>
    )
}
