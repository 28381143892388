export const sysMsg = {
    network: [
      { code: 400, text: '请求错误' },
      { code: 401, text: '认证失败' },
      { code: 403, text: '无访问权限' },
      { code: 404, text: '找不到该页面' },
      { code: 406, text: '格式错误' },
      { code: 422, text: '验证错误' },
      { code: 500, text: '服务器错误' },
      { code: 502, text: '网关错误' },
      { code: 503, text: '服务不可用' },
      { code: 504, text: '网关超时' }
    ]
  };

export const USER_PROFILE = 'userProfile'

export const INITIAL_PATH = 'initialPath'

export const INITIAL_PATH_SEARCH = 'initialPathSearch'

export const RESPONSIVE_THRESHOLD = 415
  