import React, { useEffect, useRef, useState } from 'react';
import { Input, message, Tag } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { getQuickSearchConfig } from '../../services/home';
import '../../styles/azureLessons.scss';
import '../../styles/home.scss';

export default function Search(props: {isHomeSearch: boolean, callBack?: CallableFunction, isFilterOnly?: boolean}) {
    const searchRef = useRef<Input>(null);
    const navigate = useNavigate();
    const {isHomeSearch, callBack, isFilterOnly} = props;
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [tagShow, setTagShow] = useState<boolean>(false);
    const location = useLocation();
    const [defaultValue] = useState<string>(location.state?.isNewUser === true || location.state?.isNewUser === false || isFilterOnly ? '' : location.state);
    
    useEffect(() => {
        getQuickSearchConfig().then(result => {
            setSearchTags(result);
        })
    }, []);

    const handleSearch = () => {
        let value = searchRef.current?.state.value;
        if(value){
            if(isHomeSearch){
                callBack!(value);
            }else{
                navigate(`search`, {state: value})
            }
        }else{
            if(isFilterOnly){
                callBack!('');
            }
            message.destroy();
            message.info("请输入搜索课程关键字", 3);
        }
    }

    const handleQuickSearch = () => {
        setTagShow(true);
    }

    const handleNonQuickSearch = (e: any) => {
        e.stopPropagation();
        setTagShow(false);
    }

    const handleMouseDownTag = (e: any) => {
        let tagKey = e.target.innerText;
        handleResetTag();
        e.target.style.background = 'rgba(53,152,229,0.11)';
        e.target.style.color = '#666666';
        if(isHomeSearch){
            callBack!(tagKey);
            searchRef.current?.setValue(tagKey);
        }else{
            navigate(`search`, {state: tagKey})
        }
    }

    const handleSearchChange = (e: any) => {
        // allowClear event
        if(e.type !== 'change'){
            handleResetTag();
        }
        if(e.type !== 'change' && isFilterOnly){
            callBack!('');
        }
    }

    const handleResetTag = () => {
        let tagElements = document.querySelectorAll('.typeTag') as NodeListOf<HTMLElement>;
        for(let i = 0; i < tagElements.length; i++){
            tagElements[i].style.backgroundColor = '#F6F6F6';
            tagElements[i].style.color = '#7F7F7F';
        }
    }

    return (
        <div className='global_search' style={{position: 'relative'}}>
            <Input onChange={handleSearchChange} placeholder="搜索课程" onBlur={handleNonQuickSearch} defaultValue={defaultValue} allowClear className='banner' ref={searchRef} onFocus={handleQuickSearch} onPressEnter={handleSearch} addonAfter={<SearchOutlined onClick={handleSearch}/>}/>
            <div className='tagCard global_search_tag' hidden={!tagShow}>
                {
                    searchTags?.map((item: string) => {
                        if(defaultValue && defaultValue === item){
                            return <Tag key={item} onMouseDown={handleMouseDownTag} className='typeTag' style={{backgroundColor: 'rgba(53,152,229,0.11)', color: '#666666'}}>{item}</Tag>
                        }else{
                            return <Tag key={item} onMouseDown={handleMouseDownTag} className='typeTag'>{item}</Tag>
                        }
                    })
                }
            </div>
        </div>
    )
}
