export enum HttpStatusCode {
    Ok = 200,
    CreatedAt = 201,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    ServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceNotAvailable = 503
  }