export const isMobileWxBrowser = () => {
    const ua = navigator.userAgent.toLowerCase();

    const regex = ua.match(/MicroMessenger/i);
    if(regex && regex.length > 0 && regex[0] === "micromessenger") {
        const mobile = ua.match(/Mobile/i);
        if(mobile && mobile.length > 0 && mobile[0] === "mobile") {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}

export const isCourseUrlAndFindCourseId = () => {
    let url = window.location.href;
    let homeUrl = process.env.REACT_APP_PORTAL_LOGIN_REDIRECT;
    let urlRegex = new RegExp(`^${homeUrl}(azureLessons|operationGuide)/[?a-zA-Z0-9]{8}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{12}`);
    let urlMatch = url.match(urlRegex);
    if(urlMatch && urlMatch.length > 0){
        let guidRegex = new RegExp('[?a-zA-Z0-9]{8}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{4}-[?a-zA-Z0-9]{12}');
        let guidMatch = urlMatch[0].match(guidRegex);
        return guidMatch ? guidMatch[0] : undefined;
    }else{
        return undefined;
    }
}